<template>
  <div class="vertical-center">
    <br>
    <b-container>
      <p> Watches Corner </p>
      <b-form @submit.prevent="submit()" class="col-sm-6 col-md-6 col-xl-4">
        <b-form-group label="Login">
          <b-form-input class="mb-2 mr-sm-2 mb-sm-0" placeholder="Login" v-model="login" ></b-form-input>
        </b-form-group>
        <br>
        <b-form-group label="Password">
          <b-form-input class="mb-2 mr-sm-2 mb-sm-0" placeholder="Login" type="password" v-model="password" ></b-form-input>
        </b-form-group>
        <br>
        <b-button type="submit">Log in</b-button>
        <br>
        <b-alert variant="error" v-if="errorMessage">{{ errorMessage }}</b-alert>
      </b-form>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      login: "",
      password: ""
    };
  },

  methods: {
    submit() {
      this.$emit("submit", {
        login: this.login,
        password: this.password
      });
    }
  }
};
</script>

<style scoped>
  .vertical-center {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
    width:100vw;
    margin:0 auto;
  }
  .vertical-center p {
    padding-bottom:1em;
  }
  .vertical-center form, .vertical-center p {
    max-width:300px;
    display:block;
    margin:0 auto;
  }
</style>