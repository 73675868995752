<template>
  <div>
    <App v-if="user" />
    <LoginForm v-else :errorMessage="errorMessage" @submit="login" />
  </div>
</template>

<script>

import { log_me_in } from "../authentication";
import App from "./App";
import LoginForm from "./LoginForm";

export default {
  data() {
    return {
      user: null,
      errorMessage: "",
      sharedStage: this.store.state,
    };
  },
  methods: {
    login({ login, password }) {
      log_me_in(login, password).then((token)=>{
        this.store.setToken(token);
        this.user = token;
        this.errorMessage = this.user ? null : "Authentication failed, please try again";
      });
    }
  },
  components: { App, LoginForm }
};
</script>