<template>
  <div  v-if="watch" class="main_image_container">
    <a v-bind:href="watch.url"> 
      <img v-bind:src="`https://nnext.bard.eu.com/maker/${watch['maker_id']}/watches/picture/${watch['image_path']}`" width="200px"/>
      <span>{{watch.raw_reference}}</span>
    </a>
    <br>
    <span v-if="watch.segment_start">known since: {{watch.segment_start.split('/').reverse().join('/')}}</span>
    <br>
    &nbsp;&nbsp;&nbsp; <span v-if="watch.price">{{watch.price}} €</span><span n v-if="!watch.price">NC</span>
  </div>
</template>

<script>
export default {
  name: 'Watch',
  props: {
    maker_id:{type: Number},
    reference:{type: String},
  },
  data:function(){
    return {'watch':null};
  },
  beforeMount: function(){
    this.watch = this.store.getWatchByReference(this.reference);
  },
  methods: {
    fetchWatch: function(){
      let token_headers = this.store.getToken();
      let component = this;
      fetch(this.store.getRootUrl()+'maker/'+this.maker_id+'/watches/'+this.reference,
        { headers: token_headers })
        .then(response => response.json())
        .then(json_response => {
          component.watch = json_response.watch;
        });
    }
  }
}
</script>
