import Vue from 'vue';
import AuthWrapper from './components/AuthWrapper';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

const store = {
  debug: true,
  state: {
    tokenHeader: null,
    watches:{},
  },
  getRootUrl(){
   return 'https://nnext.bard.eu.com/';
  },
  setToken (tokenResponse) {
    let tokenHeader = tokenResponse;
    this.state.tokenHeader = tokenHeader;
  },
  getToken(){
    let token = this.state.tokenHeader;
    return new Headers({ 'Authorization': 'Bearer '+token,
                         'Content-Type': 'application/x-www-form-urlencoded'});
  },
  setWatchesList(watches){
    watches.forEach(w=>{this.state.watches[w.reference] = w;})
  },
  getMakerLabel(maker_id){
    console.log(maker_id);
  },
  getWatchByReference(reference){
    return this.state.watches[reference];
  },
  clearToken () {
    this.state.tokenHeader = null;
  }
}
Vue.prototype.store = store;

new Vue({
  data:{
    sharedState: store.state,
  },
  render: h => h(AuthWrapper),
}).$mount('#app')
