<template>
  <b-container class="watchFilter">
    <b-form>
      <b-input v-model="range_min" v-bind:value="range_min" placeholder="min €"/>
      <br>
      <b-input v-model="range_max" v-bind:value="range_max" placeholder="max €"/>

      <b-form-select v-model="selectedMaker" :options="makers" @change="fetchWatches"  size="sm" class="mt-3">
      </b-form-select>
    </b-form>
    <br>
    <br>
    <b-button type="button" @click="changeRange">filter</b-button>

    <div  v-if="references && references.length>0" class="images_list">
        <Watch v-for="reference in references" v-bind:reference="reference" v-bind:maker_id="sharedState.watches[reference].maker_id" :key="reference" />
    </div>
    <div v-if="!references || references.length==0">
      <br>
      <br>
        No known watches
      <br>
    </div>
  </b-container>
</template>

<script>
import Watch from './Watch.vue';

export default {
  components: { Watch },
  name: 'HelloWorld',
  props: {
  },
  beforeMount: function(){
    this.fetchMakers();
  },
  data: function(){
    return {
      selectedMaker: '',
      range_min:null,
      range_max:null,
      number:100,
      makers: [],
      references: [],
      sharedState:this.store.state,
    };
  },
  created: function(){
    this.enableCross = false
  },
  methods: {
    changeRange: function(){
     if(this.range_min && this.range_max){
        let token_headers = this.store.getToken();
        let component = this;
        let query_string = 'search?range_min='+this.range_min +'&range_max='+this.range_max;
        if(this.selectedMaker){
          query_string = query_string +'&maker_id='+this.selectedMaker;
        }
        fetch(this.store.getRootUrl()+query_string, { headers: token_headers })
          .then(response => response.json())
          .then(json_response => {
            if(json_response.result && json_response.watches){
              component.references = json_response.watches.map((w)=>w.reference);
              component.store.setWatchesList(json_response.watches)
            } else {
              component.references = [];
              component.store.setWatchesList([]);
            }
          });
      }
    },
    fetchMakers: function(){
      let token_headers = this.store.getToken();
      let component = this;
      fetch(this.store.getRootUrl()+'makers', { headers: token_headers })
        .then(response => response.json())
        .then(json_response => {
          if(json_response.makers){
            let _options = [{value:null, text:'toute marque'}];
            for(let u=0;u<json_response.makers.length;u++){
              let m = json_response.makers[u];
              _options.push({value:m.id, text:m.label});
            }
            component.makers = _options;
          }
        });
    },
    fetchWatches: function(){
      if(this.selectedMaker){
        let token_headers = this.store.getToken();
        let component = this;
        fetch(this.store.getRootUrl()+'maker/'+this.selectedMaker+'/watches', { headers: token_headers })
          .then(response => response.json())
          .then(json_response => {
            component.references = json_response.watches.map((w)=>w.reference);
            component.store.setWatchesList(json_response.watches)
          });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.images_list {
  display:grid;
  grid-template-columns:repeat(5, 0.2fr);
  grid-gap:5px;
  margin-top:2em;
}
.main_image_container {
  font-size:10px;
  width:90px;
  text-align:center;
}
.main_image_container a + span {
  display:none;
}
.main_image_container a {
  display:block
}
.main_image_container img {
  max-width:100px;
  max-height:100px
}
.price {
  color:red;
}
.container {
  margin:3em;
}
.watchFilter {
  margin:0;
}
.watchFilter form {
  max-width: 300px;
  display:block;
  margin:0 auto;
}

@media screen and (max-width: 2048px) {
  .images_list {
    display:grid;
    grid-template-columns:repeat(6, 0.2fr);
    grid-gap:2px;
    margin-top:1em;
  }
}
@media screen and (max-width: 1024px) {
  .images_list {
    display:grid;
    grid-template-columns:repeat(4, 0.4fr);
    grid-gap:2px;
    margin-top:1em;
  }
}
@media screen and (max-width: 840px) {
  .images_list {
    display:grid;
    grid-template-columns:repeat(3, 0.4fr);
    grid-gap:4px;
    margin-top:1em;
  }
}
@media screen and (max-width: 640px) {
  .images_list {
    display:grid;
    grid-template-columns:repeat(2, 0.4fr);
    grid-gap:2px;
    margin-top:1em;
  }
}


</style>