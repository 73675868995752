/**
 * @param {String} login
 * @param {String} password
 * @return {?Object}
 */
function log_me_in(login, password) {
  let formData = new FormData();
  formData.append('username', login);
  formData.append('password', password);
  formData.append('grant_type', 'password');

  return fetch('https://nnext.bard.eu.com/token',{
        'method':'POST',
        body:formData
      })
        .then(response => response.json())
        .then((json_response) => {
          if(json_response && 'access_token' in json_response){
            return json_response.access_token;
        } else {
            return null;
        }
      });
  }

export { log_me_in }